import React from 'react'
import { Link } from 'react-router-dom'

export default function Nav() {
    return (
        <div className='container p-4'>
            <div className='row justify-content-center'>
                <nav className='col-md-9 row justify-content-between d-flex'>
                    <div className='col-md-6'>
                        <Link to="/">
                            <img className='logo d-md-inline d-block mx-auto' style={{width:'165px', height:'60px'}} src="/img/onlinebank.png" alt="" />
                        </Link>
                    </div >
                    <ul className='nav-ul col-md-6 text-center'>
                        <li> <p ><a href="tel:+917665222555" className='text-white'>+91 7665-222-555 </a></p></li>
                        <li><a className='' href="mailto:info@onlinebank.in">info@onlinebank.in</a></li>
                    </ul>
                </nav>
            </div>
        </div>
    )
}

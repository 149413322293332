import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { getCategories } from '../apiCall.js/getCategories'
import NavBar from './NavBar'
import { handleImage } from '../common'


export default function Home() {
    const [data, setData] = useState([])
    const param = useParams()
    useEffect(() => {
        getCategories().then(e => setData(e))
    }, [])



    return (
        <>
            <NavBar />
            <div className='doc_bg'>

                <div className='container'>
                    <div className=" d-flex justify-content-evenly row px-3 py-3 ">
                        <div className=" row mt-5 col-md-9 align-items-center justify-content-between">
                            <div className='score-card mt-5  col-md-6'>

                                <h4>Credit card doctor</h4>
                                <p>Arrange a call from credit card doctor</p>

                                <div className=" buttnn mt-4">
                                    <Link to="/form">
                                        <button className='btn btn-dark bttn rounded-0 '> Apply now</button>
                                    </Link>
                                </div>


                            </div>
                            <div className='mt-0 col-md-6' >
                                <img className='w-100 my-5 shadow-lg' style={{ borderRadius: "20px" }} src="https://www.laurelroad.com/wp-content/uploads/2021/03/LR4D-Doctor-Female-holding-card.jpg" alt="" />
                            </div>
                        </div>
                    </div>

                </div>

            </div>



            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-9 " id='allCategory'>

                        <div className='text-center mt-5'>
                            <h4> <b> All Category </b></h4><hr />
                        </div>

                        <div className='mt-5 container'>
                            <div className='row '>

                                {data.map((item, i) => {
                                    return (
                                        <Link key={i} to='/card' state={item.id} className='col-md-4 my-2'>
                                            <div className='d-flex justify-content-center rounded_c bg-white p-2 border_bg shadow' >
                                                <div className='d-flex flex-column justify-content-center align-items-center p-4'>
                                                    <img className='card-5-img' src={handleImage(item.name)} alt="" style={{objectFit:'cover', width:'40px', height:'40px'}} />
                                                    <p>{item.name}</p>
                                                </div>
                                            </div>
                                        </Link>
                                    )
                                }
                                )}

                            </div>
                        </div>
                    </div>

                </div>

            </div>


            <div className='page1 '>

                <div className='mt-5 container px-4 py-5'>
                    <div className='row'>
                        <div className="col-md-9 container">
                            <div className="row">

                                <div className='card3 col-md-6 d-flex align-items-center'>
                                    <img className='w-75 mt-4' src="/img/card-slider-gold.png" alt="" />
                                </div>
                                <div className='col-md-6 d-flex align-items-center'>
                                    <div className='title_'><h5 className='title1'>Why OnlineBanks.in</h5>

                                        <div className='row mt-3'>
                                            <div className='p-2 col-md-6 '>
                                                <div className='p-3  clr-1 shadow rounded-3'>
                                                    <h4 className='bank-text'>Paperless Process</h4>
                                                </div>
                                            </div>
                                            <div className='p-2 col-md-6 '>
                                                <div className='p-3  clr-1 shadow rounded-3'>
                                                    <h4 className='bank-text'>100% Contactless kyc </h4>
                                                </div>
                                            </div>
                                            <div className='p-2 col-md-12 '>
                                                <div className='p-3  clr-1 shadow rounded-3'>
                                                    <h4 className='bank-text'>All financial services on single plateform</h4>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>

                </div>
            </div>


            <div className='   cont-1'>

                <div className='container'>
                    <div className='row  py-3 px-3 justify-content-evenly'>
                        <div className='col-md-9 row  '>
                            <div className='col-md-4  d-flex align-items-center justify-content-center justify-content-md-start'>

                                <div className=' '>
                                    <p className='m-0'>Holders Support</p>
                                    <h5 className=' mb-0 text-white'> Need a Consulation ?</h5>
                                </div>

                            </div>
                            <div className='col-md-4 d-flex justify-content-center '>

                                <div className=''>
                                    <img height="35px" src="/img/icons8-ringer-volume-50.png" alt="" />
                                    <h5 className=' mt-2 '>Business Loan</h5>
                                </div>

                            </div>
                            <div className='col-md-4 btnn d-flex justify-content-center justify-content-md-end'>

                                <div className="buttnn mt-4">
                                    {/* <Link to="/form"><button className='btn btn-dark bttn rounded-0 '>  Apply now</button></Link> */}
                                    <a href="tel:+917665222555"><button className='btn btn-dark bttn rounded-0'>Call Us</button></a>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>

            </div>

            <div className='team_card'>
                <div className="row  justify-content-center container ">
                    <div className='col-md-9 text-center align-items-center '>
                        <h4 className='team-text'><b>Founding Team</b></h4><hr />
                    </div>

                    <div className='d-flex justify-content-center px-3 py-5 row mt-5 '>

                        <div className="containe m-3 mt-5 shadow col-md-3">
                            <div className="cardd " data-tilt data-tilt-glare data-tilt-max-glare="0.5" data-tilt-scale="1.1">
                                <img src='img/ramniwas.jpg' alt='Ram Niwas' style={{ width: '200px', height: '200px' }} />

                                <h1 className='text-h'>Ram Niwas Yadav</h1>
                                <p className='text-p'>Founder</p>
                            </div>
                        </div>
                        <div className="containe m-3 mt-5 shadow col-md-3">
                            <div className="cardd" data-tilt data-tilt-glare data-tilt-max-glare="0.5" data-tilt-scale="1.1">
                                <img src="img/sivraj.jpg" alt='' style={{ width: '200px', height: '200px' }} />
                                <h1 className='text-h'>Shivraj Yadav</h1>
                                <p className='text-p'>Operation Head</p>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

            <div className='testi-card'>
                <div className='container'>
                    <div className="row justify-content-center">

                        <div className='col-md-9  team_ text-center mt-5'>
                            <h4> <b>Customers Testimonials</b></h4><hr />
                        </div>


                        <div className="col-md-9 container">
                            <div className='row'>
                                <div className='col-md-4 p-3' >
                                    <div className='shadow-lg rounded-3 p-3'>

                                        <p className='card-p'>I just wanted to say that my experience with <b> online bank </b> has been fantastic. I appreciate the ease of use of their online platform and the security measures they have in place.</p>
                                        <hr />
                                        <div className='d-flex px-2 align-items-center '>
                                            <div className="testi-img" style={{ backgroundImage: "url(https://cdn.pixabay.com/photo/2014/08/06/11/19/businessman-411487__340.jpg)" }}> </div>
                                            <p className='p-2'>Ram charan</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-4  p-3' >
                                    <div className='shadow-lg rounded-3 p-3'>

                                        <p className='card-p '>I've been using <b>online bank </b> for a few months now, and I'm extremely impressed. I appreciate the security measures they have in place to protect my account.</p>
                                        <hr />
                                        <div className='d-flex px-2 align-items-center '>
                                            <div className='testi-img' style={{ backgroundImage: "url(https://cdn.pixabay.com/photo/2020/03/09/21/53/indian-4917258__340.jpg)" }}>
                                            </div>
                                            <p className='p-2'>Ravi saini</p>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-md-4  p-3' >
                                    <div className='shadow-lg rounded-3 p-3'>

                                        <p className='card-p '>I highly recommend <b> online bank</b> for their exceptional user-friendly online platform, and outstanding customer service.I have had an exceptional experience with this company.</p>
                                        <hr />
                                        <div className='d-flex px-2 align-items-center '>
                                            <div className="testi-img" style={{ backgroundImage: "url(https://cdn.pixabay.com/photo/2020/04/11/10/36/man-5029835_960_720.jpg)" }}></div>
                                            <p className='p-2'>Mohan gupta</p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>




            <div className='form-bg'>
                <div className=''>
                    <div className=' d-flex  py-5 align-items-center'>
                        <form className='p-4'>

                            <h4 className='h1-t'> Partner with us </h4>


                            <div className="container">

                                <div className="row">
                                    <div className="col-md-12">

                                        <input type="text" id="name" name="user_name" placeholder='Enter your full name' />
                                    </div>

                                    <div className='col-md-6 '>
                                        <input type="email" id="mail" name="user_phone" placeholder='Phone no.' />
                                    </div>

                                    <div className='col-md-6 '>
                                        <input type="email" id="mail" name="user_email" placeholder='Email' />
                                    </div>

                                    <div className='col-md-6 '>
                                        <input type="date" id="birthday" name="user_date" placeholder='Date of birth' />
                                    </div>
                                    <div className='col-md-6 '>
                                        <input type="text" id="pin" name="user_pin" placeholder='Pin code' />
                                    </div>
                                </div>
                            </div>

                            <div className=' d-flex justify-content-end   ' >
                                <div className=" buttn-1  mt-3  " >
                                    <button className='btn btn-dark bttn rounded-0 '>Apply now</button>
                                </div>
                            </div>

                        </form>

                        <div className='w-30 d-none d-lg-block'>
                            <img width="100%" src="/img/bg-3.png" alt="" />
                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}

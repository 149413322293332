import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom";
import { Link } from "react-router-dom";

export default function ProductDetails() {

    const location = useLocation();
    const [data, setData] = useState()

    useEffect(() => {


        fetch(`https://api.onlinebanks.in/api/v1/product?id=${location?.state}`)
            .then((response) => response.json())
            .then((data) => {
                setData(data);
            })
            .catch((error) => console.error(error));
    }, []);


    return (
        <>
            <div className='container align-items-center my-5'>
                <div className='row d-flex col-md-12'>

                    <div className='justify-content-between M-card col-md-6'>
                        <div className='d-flex justify-content-between align-items-center'>
                            <div className='text-center btn btn-secondary m-0' style={{ cursor: "auto" }}>
                                {data?.category.name}
                            </div>
                            <Link to={data?.url} target="_blank" >
                                <div className="buttnn mt-3">
                                    <button className='btn btn-dark bttn rounded-0'>Apply now</button>
                                </div>
                            </Link>
                        </div>
                        <div className='d-flex justify-content-between align-items-center'>
                            <div>

                            </div>
                        </div>
                        <div className='mt-2'>
                            <p>{data?.name}</p>
                        </div>

                        <div className='d-flex justify-content-between'>
                            <div className="caard">
                                <img src={data?.images[0]?.url ? data?.images[0]?.url : "img/card-slider-gold.png"} alt="Card Image" />
                            </div>
                        </div>

                        <div className='H-card mt-3'>
                            <div className="p-3">
                                <p className="mb-0">{data?.description}</p>
                            </div>
                        </div>


                    </div>


                    <div className='justify-content-between p-3 col-md-6'>

                        <div className="mt-4 ">
                            <h3 className="text-center">Best offerings</h3>

                        </div>

                        <ul className="mt-5">

                            {data?.feature.map((feature, index) => (
                                <li key={index} className="alert alert-success">
                                    {feature.product_feature}
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

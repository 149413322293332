export const handleImage = (name) => {
    switch (name) {
        case 'Credit Card':
            return 'img/creditCard.png';
        case 'Personal Loan':
            return 'img/business.png';
        case 'Business Loan':
            return 'img/personal.png';
        case 'Home Loan':
            return 'img/home.png';
        case 'Demat Account':
            return 'img/demat.png';
        case 'Bank Account':
            return 'img/bank.png';
        default:
            return 'img/defaultImage.png';
    }
}
import React from 'react'

export default function Footer() {
    return (
        <>

            <div className='bg-dark pt-5 pt-lg-0'>
                <div className='container text-white '>
                    <div className='footer'>
                        <div className='footer-box1' >
                            <div className="logo-1 col-lg-3 py-3 d-flex ">
                            <img className='logo d-md-inline d-block mx-auto' style={{width:'165px', height:'60px'}} src="/img/onlinebank.png" alt="" />
                            </div>

                            <p style={{lineHeight:'30px'}}>OnlineBanks.in provides all banks credit cards, <br /> personal loan, bussiness loan, home loan <br/>with exciting offers</p>

                            <div className="icons col-lg-3 py-3 d-flex">
                                <a href="https://www.facebook.com/onlinebankss?mibextid=ZbWKwL" target='_blank' rel="noreferrer">
                                    <div className="layer">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        <span className="fab fa-facebook-f"></span>
                                    </div>
                                    <div className="text">
                                        Facebook
                                    </div>
                                </a>
                                <a href="https://www.instagram.com/onlinebanks.in/" target='_blank' rel="noreferrer">
                                    <div className="layer">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        <span className="fab fa-instagram"></span>
                                    </div>
                                    <div className="text">
                                        Instagram
                                    </div>
                                </a>

                                <a href="https://youtube.com/@risingassociates55" target='_blank' rel="noreferrer">
                                    <div className="layer">
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        <span></span>
                                        <span className="fab fa-youtube"></span>
                                    </div>
                                    <div className="text">
                                        YouTube
                                    </div>
                                </a>
                            </div>
                        </div>

                        <div className='footer-box1'>
                            <div className='pt-3'>
                                <p style={{fontWeight:500, fontSize:22}}>OB Fintech India Pvt. Ltd.</p>
                            </div>
                            <div className='d-flex'>
                                <img className='icons-1' src="/img/icons8-location-32.png" alt="" />
                                <p style={{lineHeight:'30px', marginLeft:'4px'}}>Kalwar road, Jhotwara, Jaipur(Raj.) 302012</p>
                            </div>
                            <div className='d-flex'>
                                <img className='icons-1' src="/img/icons8-call-male-24.png" alt="" />
                                <p style={{marginLeft:'4px'}}><a href="tel:+917665222555  " className='text-white'>+91 7665-222-555 </a></p>
                            </div>

                            <div className='mail d-flex col-lg-3 pb-3 '>
                                <img src="/img/icons8-mail-64.png" alt="" />
                                <a className='mt-1 mb-0' href="mailto:info@onlinebank.in">info@onlinebank.in</a>
                            </div>
                        </div>


                    </div>

                </div>
            </div>


            <div className='rights'>
                <div className='text-center'>
                    <p className='mb-0'>All Right Reserved @OnlineBanks.in | Copyright @ 2023</p>
                </div>
            </div>


        </>
    )
}
